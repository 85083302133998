<script>
export default {
  name: "ProgressBar",
  props: {percent:Number, type:{type:String,default:"primary"}},
  computed:{
    percentLabel(){
      return Math.round(this.percent*100)/100
    }
  }
}
</script>

<template>
  <div class="progress">
    <div :class="'progress-bar progress-bar-'+type" :style="'width: '+percent+'%'">{{percentLabel}}%</div>
  </div>
</template>
